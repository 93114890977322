<div class="tree bordered">
	<h1>Documentation</h1>
	<dx-scroll-view>
		<dx-tree-view
			[items]="hierarchy"
			itemsExpr="children"
			displayExpr="caption"
			selectionMode="single"
			[selectByClick]="true"
			(onItemSelectionChanged)="onItemSelectionChanged($event)"
			(onInitialized)="onTreeInitialized($event)"
		>
		</dx-tree-view>
	</dx-scroll-view>
</div>
<div class="content faded">
	<h1>{{selectedItem?.caption}}</h1>
	{{selectedItem?.text}}
</div>