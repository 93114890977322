<dx-tree-view
	[dataSource]="dataSource"
	[dataStructure]="'plain'"
	(onItemClick)="onItemClick($event)"
	[focusStateEnabled]="false"
	selectionMode="single"
	[selectByClick]="true"
>
	<div
		*dxTemplate="let data of 'partner-item'; let index = index"
		class="item"
	>
		<i class="dx-icon fa-solid fa-briefcase"></i>
		<span>{{data.text}}</span>
		<div class="buttons">
			<dx-button
				class="compact"
				icon="overflow"
				stylingMode="text"
				type="default"
				(onClick)="openSubMenu($event, data)"
			>
			</dx-button>
		</div>
	</div>
	<div
		*dxTemplate="let data of 'organization-item'; let index = index"
		class="item"
	>
		<i class="dx-icon fa-solid fa-building"></i>
		<span>{{data.text}}</span>
	</div>

</dx-tree-view>

<dx-popover
	[hideOnOutsideClick]="true"
	[shading]="false"
	[(visible)]="subMenuVisible"
	[position]="subMenuPosition"
>
	<div
		*dxTemplate="let data of 'content'"
		class="submenu-content"
	>
		<dx-button
			text="Add partner"
			icon="fa-solid fa-briefcase"
			(onClick)="subMenuVisible = false; addPartner()"
			stylingMode="text"
		>
		</dx-button>
		<dx-button
			text="Add organization"
			icon="fa-solid fa-building"
			(onClick)="subMenuVisible = false; addOrganization()"
			stylingMode="text"
		>
		</dx-button>
	</div>
</dx-popover>

<dx-popup
	(onShown)="addModelPopupShown($event)"
	[(visible)]="addOrganizationPopupVisible"
	[closeOnOutsideClick]="true"
	[ngClass]="appInfoService.theme == 'light' ? 'dx-swatch-tw2-light' : 'dx-swatch-tw2-dark'"
	height="auto"
	title="Add organization"
	width="400"
>
	<tw2-organization-edit
		[useLoadingPanel]="false"
		[model]="newOrganization"
		(onSave)="addOrganizationPopupVisible = false"
	></tw2-organization-edit>
</dx-popup>

<dx-popup
	[(visible)]="addPartnerPopupVisible"
	[closeOnOutsideClick]="true"
	[ngClass]="appInfoService.theme == 'light' ? 'dx-swatch-tw2-light' : 'dx-swatch-tw2-dark'"
	height="auto"
	title="Add partner"
	width="400"
	(onShown)="addModelPopupShown($event)"
>
	<tw2-partner-edit
		[useLoadingPanel]="false"
		[model]="newPartner"
		(onSave)="addPartnerPopupVisible = false"
	></tw2-partner-edit>
</dx-popup>