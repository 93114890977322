import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Subject, catchError, map, throwError } from 'rxjs';
import { SiteStackStationProtocol } from 'sitestack-common';
import { Environment } from '../../environments/environment-interface';
import { ENVIRONMENT } from '../app.module';

@Injectable()
export class SiteStackStationCommandService {
	public errorHandler = new Subject<string>();

	constructor(
		@Inject(ENVIRONMENT) protected environment: Environment,
		protected http: HttpClient
	) { }

	public request<S extends SiteStackStationProtocol.Subject, D extends SiteStackStationProtocol.DownlinkRequests[S], K extends keyof D, X extends D[K]>(
		endpointId: string,
		subject: S,
		command: K,
		//@ts-expect-error
		request: Parameters<X>[0] = {},
		timeout = 5000
	) {
		const url = `${[this.getBaseURL(endpointId), subject, command].join('/')}?timeout=${timeout}`;
		//@ts-expect-error
		return this.http.post<ReturnType<X>>(url, request)
			.pipe(
				catchError((err) => this.handleError(err)),
				map(m => m)
			);
	}

	private getBaseURL(endpointId: string) {
		return `https://${endpointId}.${this.environment.sitestackStationCommandProxyDomain}`;
	}

	private handleError(httpError: HttpErrorResponse) {
		if (httpError.error instanceof ErrorEvent) {
			// A client-side or network error occurred. Handle it accordingly.
			console.error('An error occurred:', httpError.error.message);
			return throwError(httpError.error.message);
		} else {
			// The backend returned an unsuccessful response code.
			// The response body may contain clues as to what went wrong.
			console.error('Error', httpError)
			console.error(
				`Backend returned code ${httpError.status}, ` +
				`body was: ${httpError.error}`);
			return throwError(httpError.error);
		}
	}
}
