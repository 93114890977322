import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { Observable, catchError, switchMap } from 'rxjs';
import { Environment } from '../../environments/environment-interface';
import { ENVIRONMENT } from '../app.module';

@Injectable({ providedIn: 'root' })
export class HTTPAuthInterceptor implements HttpInterceptor {
	constructor(
		private authService: AuthService,
		@Inject(ENVIRONMENT) private environment: Environment
	) { }

	public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const requestHost = new URL(request.url).host;

		const apiHosts = [
			this.environment.inventoryAPIURL,
			this.environment.tw2APIURL,
			this.environment.sitestackStationCommandProxyDomain
		].map(api => {
			if (api.includes('://'))
				return new URL(api).host
			return api;
		})

		const intercept = apiHosts.some(host => requestHost.endsWith(host))
		//console.log('INTERCEPT', requestHost, intercept, apiHosts);

		if (!intercept)
			return next.handle(request);

		//console.log('LOADING TOKEN', service.api.audience);
		return this.authService.getAccessTokenSilently({
			authorizationParams: {
				audience: this.environment.auth0.audience,
			}
		})
			.pipe(
				catchError((err, ca) => {
					console.log('ERROR. Failed to load token', err);
					this.authService.logout();
					return ca;
				}),
				switchMap((token) => {
					//console.log('GOT TOKEN', token);
					request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
					return next.handle(request);
				}));
	}
}