import mqtt from 'mqtt';
import { ReplaySubject } from 'rxjs';

export class MQTTService {

	private client = new ReplaySubject<mqtt.MqttClient>();

	constructor(private options: mqtt.IClientOptions) {
		//console.log('OPTIONS', options);
	}

	public connect() {
		//console.log('CONNECT');

		//this.options.protocolVersion = 4;

		const client = mqtt.connect(this.options);

		client.on('connect', (e) => {
			// console.log('MQTT connect', e);
			this.client.next(client);
		})
		// client.on('message', (e) => {
		// 	console.log('MQTT message', e);
		// })
		// client.on('packetsend', (e) => {
		// 	console.log('MQTT packetsend', e);
		// })
		// client.on('packetreceive', (e) => {
		// 	console.log('MQTT packetreceive', e);
		// })
		client.on('disconnect', (e) => {
			console.log('MQTT disconnect', e);
		})
		client.on('error', (e) => {
			console.log('MQTT error', e);
		})
		client.on('close', () => {
			console.log('MQTT close');
		})
		client.on('end', () => {
			console.log('MQTT end');
		})
		// client.on('reconnect', (e) => {
		// 	console.log('MQTT reconnect', e);
		// })
		// client.on('offline', (e) => {
		// 	console.log('MQTT offline', e);
		// })
		// client.on('outgoingEmpty', (e) => {
		// 	console.log('MQTT outgoingEmpty', e);
		// })

	}

	public getClient(): ReplaySubject<mqtt.MqttClient> {
		return this.client;
	}


}
