import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, filter, finalize, tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class HTTPCacheInterceptor implements HttpInterceptor {
	private requests: {
		src: string;
		data$: Subject<HttpResponse<any>>;
		params?: any;
	}[] = [];

	public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

		const prevSameRequest = () => {
			return this.requests.find(
				(x) =>
					x.src === req.url &&
					JSON.stringify(x.params) === JSON.stringify(req.body)
			);
		};

		const sameRequest = prevSameRequest();

		if (sameRequest) {
			if (!sameRequest.data$.closed) return sameRequest.data$;
			sameRequest.data$ = new Subject<any>();
		} else {
			this.requests.push({
				src: req.url,
				data$: new Subject<HttpResponse<any>>(),
				params: req.body,
			});
		}

		return next.handle(req).pipe(
			filter((x) => x instanceof HttpResponse),
			tap((x) => {
				const r = prevSameRequest();
				!r?.data$.closed && r?.data$.next(x as HttpResponse<any>);
			}),
			finalize(() => {
				const r = prevSameRequest();
				r?.data$.complete();
				r?.data$.unsubscribe();
			})
		);
	}
}