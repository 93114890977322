import { Component } from '@angular/core';
import DevExpress from 'devextreme';
import { OrganizationModel, Paths } from 'tw2-common';
import { Action, ModelEditComponent, ModelEditComponentImports } from '../model-edit/model-edit.component';

@Component({
	selector: 'tw2-organization-edit',
	templateUrl: '../model-edit/model-edit.component.html',
	styleUrls: ['../model-edit/model-edit.component.scss'],
	standalone: true,
	imports: ModelEditComponentImports
})
export class OrganizationEditComponent extends ModelEditComponent<OrganizationModel> {
	protected modelType = OrganizationModel;

	public actions: Action[] = [];
	public deleteAction = {
		title: 'Delete organization',
		message: 'The organization and all its data will be removed.'
	};

	protected async getItems(): Promise<(DevExpress.ui.dxForm.Item & { dataField?: Paths<OrganizationModel> })[]> {
		return [
			{
				dataField: 'name',
				isRequired: true
			},
			{
				dataField: 'accountId',
				isRequired: true
			}
		];
	}

	protected async ready(): Promise<void> { }
}
