import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

type UserConnection = {
	connection: string,
	logo: string,
	banner: string
}

@Injectable({
	providedIn: 'root'
})
export class UCLookupService {

	constructor(
		protected http: HttpClient
	) { }

	private handleError(httpError: HttpErrorResponse) {

		if (httpError.error instanceof ErrorEvent) {
			// A client-side or network error occurred. Handle it accordingly.
			console.error('An error occurred:', httpError.error.message);
			return throwError(httpError.error.message);
		} else {
			// The backend returned an unsuccessful response code.
			// The response body may contain clues as to what went wrong.
			console.log('Error', httpError)
			console.error(
				`Backend returned code ${httpError.status}, ` +
				`body was: ${httpError.error}`);
			return throwError(httpError.error);
		}
	}

	public read(
		domain: string
	): Observable<UserConnection> {
		if (domain == 'tw2.local' || domain.includes('192.168'))
			domain = 'jpa.thingsweb.wtf';
		//domain = 'jpa.thingsweb.one';
		console.log('DOMAIN', domain);
		return this.http.get<UserConnection>(`${environment.ucLookupURL}/${domain}`)
			.pipe(
				catchError(this.handleError),
			);
	}

}
