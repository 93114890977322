import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Environment } from '../../environments/environment-interface';
import { ENVIRONMENT } from '../app.module';
import { SearchResult } from '../components/organization/location/location-edit.component';

@Injectable()
export class GoogleGeocodeService {
	constructor(
		@Inject(ENVIRONMENT) protected environment: Environment,
		protected http: HttpClient
	) { }

	public getCoordinates(address: string): Observable<SearchResult[]> {
		return this.http.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${this.environment.googleAPIsKey}`)
			.pipe(
				catchError(this.handleError as any),
				map((m: google.maps.GeocoderResponse) => {

					return (m.results as google.maps.GeocoderResult[]).map(r => {
						const location = (r.geometry.location as unknown as google.maps.LatLngLiteral);

						const result: SearchResult = {
							altitude: 0,
							latitude: location.lat,
							longitude: location.lng,
							label: r.formatted_address,
							id: r.place_id
						};
						return result;
					})
				}

				)
			);
	}

	private handleError(httpError: HttpErrorResponse) {
		if (httpError.error instanceof ErrorEvent) {
			// A client-side or network error occurred. Handle it accordingly.
			console.error('An error occurred:', httpError.error.message);
			return throwError(httpError.error.message);
		} else {
			// The backend returned an unsuccessful response code.
			// The response body may contain clues as to what went wrong.
			console.log('Error', httpError)
			console.error(
				`Backend returned code ${httpError.status}, ` +
				`body was: ${httpError.error}`);
			return throwError(httpError.error);
		}
	}
}
