import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ListOptions } from 'tw2-common';
import { BaseEntity, getURLKey } from 'tw2-inventory-common';
import { Environment } from '../../environments/environment-interface';
import { ENVIRONMENT } from '../app.module';

@Injectable()
export class InventoryHTTPService {
	public errorHandler = new Subject<string>();

	constructor(
		@Inject(ENVIRONMENT) protected environment: Environment,
		protected http: HttpClient
	) { }

	public createEntity<T extends BaseEntity>(
		entity: BaseEntity
	): Observable<T> {
		const entityType = entity.constructor as new () => T;
		const key = getURLKey(entityType);
		return this.http.post(`${this.environment.inventoryAPIURL}/${key}/vendor/${entity.vendorId}/model/${entity.modelId}`, entity)
			.pipe(
				catchError(this.handleError),
				map(m => Object.assign(new entityType(), m))
			);
	}

	public deleteEntity<T extends BaseEntity>(
		entityType: new () => T,
		id: string
	): Observable<T> {
		const key = getURLKey(entityType);
		return this.http.delete(`${this.environment.inventoryAPIURL}/${key}/${id}`)
			.pipe(
				catchError(this.handleError),
				map(m => Object.assign(new entityType(), m))
			);
	}

	public listEntities<T extends BaseEntity>(
		entityType: new () => T,
		options?: ListOptions<T>,
	): Observable<{ data: T[], totalCount: number }> {
		const key = getURLKey(entityType);
		let params = new HttpParams();
		if (options)
			Object.entries(options).forEach(([k, v]) => {
				if (v !== undefined)
					params = params.set(k, JSON.stringify(v));
			});
		console.log('List entities', key);
		return this.http.get<T[]>([this.environment.inventoryAPIURL, key].join('/'), { params, observe: 'response' })
			.pipe(
				catchError(this.handleError),
				map(x => {
					const c = x.headers.get('x-count');
					const totalCount = c == undefined ? -1 : parseInt(c);
					const data = x.body!.map(m => Object.assign(new entityType(), m));
					return {
						data,
						totalCount
					};
				}),
			);
	}

	public readEntity<T extends BaseEntity>(
		entityType: new () => T,
		id: string
	): Observable<T> {
		const key = getURLKey(entityType);
		console.log('Read entity', key);
		return this.http.get(`${this.environment.inventoryAPIURL}/${key}/${id}`)
			.pipe(
				catchError(this.handleError),
				map(m => Object.assign(new entityType(), m))
			);
	}

	private handleError(httpError: HttpErrorResponse) {
		if (httpError.error instanceof ErrorEvent) {
			// A client-side or network error occurred. Handle it accordingly.
			console.error('An error occurred:', httpError.error.message);
			return throwError(httpError.error.message);
		} else {
			// The backend returned an unsuccessful response code.
			// The response body may contain clues as to what went wrong.
			console.log('Error', httpError)
			console.error(
				`Backend returned code ${httpError.status}, ` +
				`body was: ${httpError.error}`);
			return throwError(httpError.error);
		}
	}
}
