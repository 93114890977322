import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { InjectionToken, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AuthModule, AuthService } from '@auth0/auth0-angular';
import { DxDrawerModule, DxToolbarModule } from 'devextreme-angular';
import { IClientOptions } from 'mqtt';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { TreeComponent } from './components/tree/tree.component';
import { HTTPAuthInterceptor } from './interceptors/http-auth.interceptor';
import { HTTPCacheInterceptor } from './interceptors/http-cache.interceptor';
import { AppInfoService } from './services/app-info.service';
import { GoogleGeocodeService } from './services/google.geocode.service';
import { InventoryHTTPService } from './services/inventory.http.service';
import { ModelHTTPService } from './services/model.http.service';
import { ModelMQTTService } from './services/model.mqtt.service';
import { MQTTService } from './services/mqtt.service';
import { SiteStackStationCommandService } from './services/sitestack-station-command.service';
import { SiteStackVideoStreamService } from './services/sitestack-video-stream.service';
import { UCLookupService } from './services/uc-lookup.service';

export const ENVIRONMENT = new InjectionToken('API_URL');

@NgModule({
	declarations: [
		AppComponent
	],
	bootstrap: [AppComponent], imports: [AppRoutingModule,
		AuthModule.forRoot({
			clientId: environment.auth0.clientId,
			domain: environment.auth0.domain,
			authorizationParams: {
				//audience: environment.services.tw2.api.audience,
				redirect_uri: window.location.origin,
			},
			cacheLocation: 'localstorage',
			// httpInterceptor: {
			// 	allowedList: [`${environment.apiURL}/*`]
			// }
		}),
		TreeComponent,
		HomeComponent,
		BrowserModule,
		DxDrawerModule,
		DxToolbarModule], providers: [
			{
				provide: ENVIRONMENT,
				useValue: environment
			},
			{
				provide: ModelMQTTService,
				useFactory: (authService: AuthService) => {
					const cfg: IClientOptions = Object.assign({}, environment.tw2MQTTBroker);
					const mqttService = new MQTTService(cfg);
					authService.isAuthenticated$.subscribe(b => {
						authService.getAccessTokenSilently({
							authorizationParams: {
								audience: environment.auth0.audience
							}
						}).subscribe(async token => {
							cfg.username = token;
							cfg.password = ' ';
							mqttService.connect();
						})
					})
					return new ModelMQTTService(mqttService);
				},
				deps: [AuthService]
			},
			{
				provide: HTTP_INTERCEPTORS,
				useClass: HTTPAuthInterceptor,
				multi: true
			},
			{
				provide: HTTP_INTERCEPTORS,
				useClass: HTTPCacheInterceptor,
				multi: true
			},
			UCLookupService,
			GoogleGeocodeService,
			SiteStackVideoStreamService,
			SiteStackStationCommandService,
			AppInfoService,
			InventoryHTTPService,
			ModelHTTPService,
			provideHttpClient(withInterceptorsFromDi()),
		]
})

export class AppModule {
}
