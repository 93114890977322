import { Component } from '@angular/core';
import DevExpress from 'devextreme';
import DXFileUploader from 'devextreme/ui/file_uploader';
import { PartnerModel, Paths } from 'tw2-common';
import { Action, ModelEditComponent, ModelEditComponentImports } from '../model-edit/model-edit.component';

@Component({
	selector: 'tw2-partner-edit',
	templateUrl: '../model-edit/model-edit.component.html',
	styleUrls: ['../model-edit/model-edit.component.scss', './partner-edit.component.scss'],
	standalone: true,
	imports: ModelEditComponentImports
})
export class PartnerEditComponent extends ModelEditComponent<PartnerModel> {
	protected modelType = PartnerModel;

	public actions: Action[] = [];
	public deleteAction = {
		title: 'Delete partner',
		message: 'The partner and all its data will be removed.'
	};

	public override async ngOnInit() {
		super.ngOnInit();
		this.route.queryParams.subscribe(params => {
			if ('parentId' in params)
				this.model!.parentId = params['parentId']
		});
	}

	protected async getItems(): Promise<(DevExpress.ui.dxForm.Item & { dataField?: Paths<PartnerModel>; })[]> {
		const iFields: ['logo' | 'banner', string, string, string][] = [
			['logo', 'Logo', '.png', 'image/png'],
			['banner', 'Banner', '.jpg', 'image/jpeg'],
		]

		const imageFields = iFields.map(([dataField, name, extension, mimeType]) => {
			return {
				itemType: 'group',
				caption: name,
				items: [
					{
						dataField: dataField,
						label: { visible: false },
						template: (data: any, itemElement: HTMLElement) => {
							if (this.model && this.model[dataField]) {
								const img = document.createElement('img');
								img.classList.add('logo')

								const image = this.model[dataField] as string;

								if (image.endsWith(extension)) {
									// Logo is path
									img.setAttribute('src', `${this.environment.assetServerURL}/${image}?token=${this.appInfoService.getToken()}`);
								} else {
									// Logo is base64 encoded
									img.setAttribute('src', `data:${mimeType};base64, ${image}`);
								}

								itemElement.appendChild(img);
								return;
							}

							itemElement.appendChild(document.createTextNode(`${name} not set.`));
						}
					},
					{
						itemType: 'simple',
						template: (data: any, itemElement: HTMLElement) => {
							const div = document.createElement('div');
							itemElement.appendChild(div);
							const x = new DXFileUploader(div, {
								selectButtonText: "Upload image",
								labelText: "",
								accept: mimeType,
								uploadMode: "useForm",
								showFileList: false,
								onValueChanged: (e) => {
									if (e.value && e.value[0]) {
										const file = e.value[0];
										const fr = new FileReader();
										fr.onload = () => {
											const str = btoa(fr.result as string);
											this.model![dataField] = str;
											this.form.instance.repaint()
										};
										fr.readAsBinaryString(file);
									} else {
										console.log("File not selected or browser incompatible.")
									}
								}
							});
						}
					}
				]
			} as (DevExpress.ui.dxForm.Item & { dataField?: Paths<PartnerModel>; })
		});

		return [
			{
				itemType: 'group',
				caption: 'Settings',
				items: [
					// {
					// 	dataField: 'parentId',
					// 	editorType: 'dxSelectBox',
					// 	editorOptions: {
					// 		readOnly: true,
					// 		onInitialized: (e) => {
					// 			this.mqttList(PartnerModel, partners => {
					// 				console.log(partners);
					// 				e.component!.option('items', partners)
					// 			})
					// 		},
					// 		displayExpr: 'name',
					// 		valueExpr: 'id'

					// 	} as DevExpress.ui.dxSelectBox.Properties,
					// 	//isRequired: true
					// },
					{
						dataField: 'name',
						isRequired: true
					},
					{
						dataField: 'domainName',
						helpText: this.model?.isNew ? "This cannot be changed later." : "",
						isRequired: true,
						editorOptions: {
							readOnly: !this.model?.isNew,
						} as DevExpress.ui.dxTextBox.Properties
					},

				]
			},
			{
				itemType: 'group',
				caption: 'Extensions',
				items: [
					{
						label: { text: 'Enable Analyzero' },
						dataField: 'extensionAnalyzeroEnabled',
						editorType: 'dxCheckBox'
					},
					{
						label: { text: 'Enable Carie' },
						dataField: 'extensionCarieEnabled',
						editorType: 'dxCheckBox'
					},
					{
						label: { text: 'Enable Sitewatch' },
						dataField: 'extensionSitewatchEnabled',
						editorType: 'dxCheckBox'
					},

				]
			},
			...imageFields

		]
	}

	protected async ready(): Promise<void> { }
}
