import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { SiteStackCamera } from 'sitestack-common';
import { environment } from 'src/environments/environment';
import { SiteStackCameraModel } from 'tw2-common';
import { AppInfoService } from './app-info.service';

export type Range = {
	start: Date,
	end: Date
}

@Injectable()
export class SiteStackVideoStreamService {
	constructor(
		private appInfoService: AppInfoService,
		protected http: HttpClient
	) { }

	public getInit(model: SiteStackCameraModel, channel: SiteStackCamera.Channel): Observable<ArrayBuffer> {
		const url = this.getURL(model, channel, "http", "init");
		return this.http.get(
			url,
			{
				responseType: 'text',
				headers: {
					'authorization': `Bearer ${this.appInfoService.getToken()}`
				}
			}
		).pipe(map(x => Uint8Array.from(atob(x), c => c.charCodeAt(0))));
	}

	public getMIME(model: SiteStackCameraModel, channel: SiteStackCamera.Channel): Observable<string> {
		const url = this.getURL(model, channel, "http", "mime");
		return this.http.get(
			url,
			{
				responseType: 'text',
				headers: {
					'authorization': `Bearer ${this.appInfoService.getToken()}`
				}
			}
		);
	}

	public getVideoSocket(model: SiteStackCameraModel, channel: SiteStackCamera.Channel): WebSocket {
		const url = this.getURL(model, channel, "ws");
		const ws = new WebSocket(url, this.appInfoService.getToken());
		ws.binaryType = 'arraybuffer';
		return ws;
	}

	// public getObjectDetectionSocket(model: SiteStackCameraModel): WebSocket {
	// 	let url = `${environment.services.tw2.api.url.replace(/^http/, 'ws')}/ws-proxy-server/cameras/${model.id}/object-detection`;
	// 	const ws = new WebSocket(url, [this.appInfoService.getToken(), model.stationId]);
	// 	return ws;
	// }

	// public getPlaybackPosition(model: SiteStackCameraModel) {
	// 	return this.http.get<Date>(
	// 		`${environment.services.tw2.api.url}/ws-proxy-server/cameras/${model.id}/playback/position`,
	// 		{
	// 			headers: {
	// 				'tw2-id': model.stationId
	// 			},
	// 		}
	// 	).pipe(map(r => new Date(r)));
	// }

	// public getPlaybackRange(model: SiteStackCameraModel): Observable<Range> {
	// 	return this.http.get<{ start: Date, end: Date }>(
	// 		`${environment.services.tw2.api.url}/ws-proxy-server/cameras/${model.id}/playback/range`,
	// 		{
	// 			headers: {
	// 				'tw2-id': model.stationId
	// 			}
	// 		}
	// 	).pipe(map(r => ({
	// 		start: new Date(r.start),
	// 		end: new Date(r.end)
	// 	})));
	// }

	// public setPlaybackPosition(model: SiteStackCameraModel, position: Date) {
	// 	return this.http.put(
	// 		`${environment.services.tw2.api.url}/ws-proxy-server/cameras/${model.id}/playback/position`,
	// 		{ position },
	// 		{
	// 			headers: {
	// 				'tw2-id': model.stationId
	// 			},
	// 		}
	// 	);
	// }
	// private getChannelPath(model: SiteStackCameraModel, channel: SiteStackCamera.Channel) {
	// 	return [
	// 		...getPath(model.constructor as new () => SiteStackCameraModel, model),
	// 		channel,
	// 		'client'
	// 	].join('/')
	// }

	private getURL(model: SiteStackCameraModel, channel: SiteStackCamera.Channel, protocol: 'ws' | 'http', path: string = "") {
		return `${protocol}s://${model.mac.replaceAll(':', '')}-${channel}.${environment.sitestackVideoProxyDomain}/${path}`;
	}
}
