<ng-template
	[ngIf]="loading"
	[ngIfElse]="ready"
>

	<ng-container *ngIf="useLoadingPanel">
		<div id="loadingFiller"></div>
		<dx-load-panel
			[position]="{ of: '#loadingFiller' }"
			[visible]="true"
			[showIndicator]="true"
		>
		</dx-load-panel>
	</ng-container>

</ng-template>

<ng-template #ready>
	<dx-form
		[(formData)]="model"
		[items]="itemsWrapper"
		labelMode="outside"
	>

		<div *dxTemplate="let data of 'dynamicTemplate'">
			<ng-template
				appDynamicContent
				(onReady)="onDynanicContentReady($event, data)"
			>
			</ng-template>
		</div>

	</dx-form>
	<div class="buttons">
		<dx-button
			[text]="saveButtonOptions.text!"
			[type]="saveButtonOptions.type!"
			(onClick)="saveButtonOptions.onClick!($event)"
			[disabled]="saveButtonOptions.disabled!"
		></dx-button>
	</div>

	<ng-container *ngFor="let action of actions">
		<div
			*ngIf="action.visibility == 'both' || (action.visibility == undefined && model?.id == undefined) || (action.visibility == 'existing' && model?.id !== undefined)"
			[ngClass]="['action', 'dx-card', action.type]"
		>
			<div class="content">
				<div class="text">
					<header>{{action.title}}</header>
					{{action.message}}
				</div>
				<dx-button
					[text]="action.button.text"
					[icon]="action.button.icon"
					[type]="action.type"
					(onClick)="action.button.onClick()"
				></dx-button>
			</div>
		</div>
	</ng-container>

	<div *ngIf="deleteAction && model?.id !== undefined">
		<dx-multi-view
			[animationEnabled]="true"
			[swipeEnabled]="false"
			[selectedIndex]="deleteActionStep"
		>
			<dxi-item>
				<div
					*dxTemplate
					class="action dx-card danger initial"
				>
					<div class="content">
						<div class="text">
							<header>{{deleteAction.title}}</header>
							{{deleteAction.message}}
						</div>
						<dx-button
							text="Delete"
							icon="fa-solid fa-trash"
							type="danger"
							(onClick)="deleteActionStep = 1"
						></dx-button>
					</div>
				</div>
			</dxi-item>
			<dxi-item>
				<div
					*dxTemplate
					class="action dx-card danger confirmation"
				>
					<div class="content">
						<i class="icon dx-icon-warning"></i>
						<div class="text">
							<header>{{deleteAction.title}}</header>
							Are you really sure you want to delete "{{this.model?.label}}? This cannot be undone!
						</div>
					</div>
					<div class="buttons">
						<dx-button
							text="Cancel"
							(onClick)="deleteActionStep = 0"
						></dx-button>
						<dx-button
							text="Delete"
							type="danger"
							(onClick)="delete()"
						></dx-button>
					</div>
				</div>
			</dxi-item>
		</dx-multi-view>
	</div>

</ng-template>