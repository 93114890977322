<ng-container *ngIf="authenticated">

	<div
		id="header"
		class="dx-theme-background-color bordered"
	>
		<dx-toolbar id="main-toolbar">
			<dxi-item location="before">
				<div *dxTemplate>
					<a
						id="logo"
						[routerLink]="['/']"
					></a>
				</div>
			</dxi-item>
			<dxi-item
				location="before"
				widget="dxButton"
				locateInMenu="never"
				[options]="toggleDrawerButtonOptions"
			>
			</dxi-item>

			<dxi-item
				location="after"
				widget="dxButton"
				locateInMenu="never"
				[options]="toggleThemeButtonOptions"
			>
			</dxi-item>

			<dxi-item
				location="after"
				widget="dxDropDownButton"
				locateInMenu="never"
				[options]="userMenuOptions"
			>
			</dxi-item>
		</dx-toolbar>
	</div>

	<dx-drawer
		template="template"
		[(opened)]="drawerOpened"
		id="drawer"
	>
		<div
			*dxTemplate="let data of 'template'"
			id="tree-wrapper"
			class="dx-swatch-tw2-dark bordered"
		>
			<div class="title">Partners and organizations</div>
			<tw2-tree></tw2-tree>
		</div>

		<div id="content-wrapper">
			<router-outlet></router-outlet>
		</div>
	</dx-drawer>

</ng-container>