import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';

const routes: Routes = [
	{
		path: '',
		component: HomeComponent
	},
	{
		path: 'partners',
		loadChildren: () => import('./components/partner/partner.module').then(c => c.PartnerModule),
	},
	{
		path: 'partners/:partnerId/organizations',
		loadChildren: () => import('./components/organization/organization.module').then(m => m.OrganizationModule),
	},
	{
		path: '**',
		redirectTo: 'home'
	},

];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
